<template>
  <div class="form-box">
    <FormTitle :infoName="infoName" />
    <div class="form-list">
      <el-form :model="ruleForm" ref="ruleForm" label-position="top" class="demo-ruleForm" @submit.native.prevent>
        <el-form-item label="请输入SCI论文标题">
          <el-input v-model="ruleForm.title"
                    type="text"
                    placeholder="请输入完整的英文标题，标题越完整，创作内容越准确"
                    maxlength="150"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="上传资料 (选填)：">
          <el-upload
            class="upload-demo"
            drag
            :action="''"
            ref="upload"
            accept=".pdf,.PDF"
            :http-request="handleFileUpload"
            multiple
            :limit="10"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-exceed="handleExceed"
            :before-remove="beforeRemove"
            :before-upload="handleBeforeUpload"
            :file-list="fileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text radio-pc-show">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__text radio-h5-show"><em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">最多上传10篇, 每篇不得超过3M</div>
            <div class="el-upload__tip" slot="tip">(将资料输入到AI模型中进行预训练，以便让AI模型更贴近你的写作风格。)</div>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-checkbox class="form-check" v-model="btnInfo.checked">{{btnInfo.title}}</el-checkbox>
      <el-button style="width: 180px" type="primary" :disabled="!btnInfo.checked" @click="submitForm('ruleForm')">立即创作</el-button>
    </div>

    <NoticeWrapper />
  </div>
</template>

<script>
  import CommonApi from "@/api/common"
  import axios from 'axios'
  import { orderMixin } from '@/utils/orderMixin'
  import FormTitle from "@/components/FormTitle/index.vue"
  import NoticeWrapper from "@/components/NoticeWrapper/index.vue"
  export default {
    components: {NoticeWrapper, FormTitle},
    mixins: [orderMixin],
    props: ['infoName'],
		data() {
			return {
        ruleForm: {
          title: '',
          creationDocId: 14,
          creationDocCode: 'SCI',
          channelCode: null,
          extParam: {
            version: 1,
          }
        },
        // rules: {
        //   title: [
        //     { required: true, message: '请输入SCI论文标题', trigger: 'blur' },
        //     { pattern: /^[A-Za-z](?:[A-Za-z ,.])*$/, message: '请输入纯英文', trigger: 'blur' }
        //   ]
        // },
        btnInfo: {
          title: '我已阅读并同意: 所有的范文仅用于格式和思路参考',
          checked: true
        },
        fileList: [],
        temporaryFiles: [],
        uploadUrl: '',
        headers: {},
        paramsData: '', // 储存跳页面的传值
			}
		},
		created() {},
		methods: {
      // 处理移除操作
      handleRemove(file, fileList) {
        // 返回false阻止默认的删除行为
        return false;
      },
      // 移除之前的操作
      beforeRemove(file, fileList) {
        // console.log('remove', file, fileList);
        // return this.$confirm(`确定移除 ${ file.name }？`);
      },
      // 处理超出图片个数操作
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      // 监听到用户操作调用获取上传地址
      handleChange(file,fileList){
        // console.log('回调:',file, fileList);
        // 如果你需要记录临时文件，可以这样：
        // 注意这里使用 fileList 而不是单个 file
        this.temporaryFiles = fileList
        // this.temporaryFiles = file
      },
      // 处理文件上传操作
      handleFileUpload(file) {
        this.uploadInfo(file)
      },
      // 获取文件上传信息
      uploadInfo(file){
        let _this = this
        CommonApi.uploadInfo().then(res => {
          _this.uploadUrl = res.data.signedUrl
          _this.upload(file)
        }).catch(() => {
        })
      },
      upload(file){
        let _this = this
        // 上传的接口需要定义Content-type
        const headers = {'Content-Type': 'application/pdf'}
        axios.put(_this.uploadUrl,file.file,{
          headers: headers
        }).then((res) => {
          // this.fileList = []
          this.fileList.push(file.file)
          this.$message.success('上传成功!')
        }).catch((error) => {
          // console.log('error', error)
          this.$refs.upload.clearFiles()
        });
      },
      // 上传成功不删除文件列表
      handleSuccess(file, fileList) {
        // console.log(file,fileList)
        this.$message.success('文件上传成功');
      },
      handleBeforeUpload(file){
        const testmsg = file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testmsg === 'PDF' ||  testmsg === 'pdf';
        if(!extension ) {
          this.$message({
            message: '上传文件只能是 PDF 格式!',
            type: 'error'
          });
          return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2
        if(!isLt2M) {
          this.$message({
            message: '上传文件大小不能超过 2MB!',
            type: 'error'
          });
          return false;
        }
        return extension || isLt2M
      },
      isPureEnglish(text) {
        const pattern = /^[A-Za-z](?:[A-Za-z ,.])*$/
        return pattern.test(text)
      },
      submitForm(formName) {
        let _this = this

        if(_this.ruleForm.title === ''){
          return _this.$message({
            message: '请输入论文标题',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }
        if(!_this.isPureEnglish(_this.ruleForm.title)){
          return _this.$message({
            message: '请输入纯英文标题!',
            type: 'warning',
            duration: 2000,
            customClass: "focus-message",
          });
        }

        _this.paramsData = _this.ruleForm
        let info = JSON.stringify(_this.ruleForm.extParam)
        _this.ruleForm.extParam = info
        //生成订单 mixin
        _this.createOrder(_this.ruleForm,_this.paramsData)

        // this.$refs[formName].validate((valid) => {
        //   if (valid) {
        //     _this.paramsData = _this.ruleForm
        //     let info = JSON.stringify(_this.ruleForm.extParam)
        //     _this.ruleForm.extParam = info
        //     //生成订单 mixin
        //     _this.createOrder(_this.ruleForm,_this.paramsData)
        //   } else {
        //     return false;
        //   }
        // });
      },
      // resetForm(formName) {
      //   this.$refs[formName].resetFields();
      // }
		}
	}
</script>

<style lang="less" scoped>
@import "@/assets/css/pageContent";
/deep/ .upload-demo{
  width: 50%;
  .el-upload__tip{
    margin: 0;
    height: 24px;
    line-height: 24px;
  }
  .el-upload-list{
    overflow: auto;
    max-height: 160px;
  }
}
@media screen and (max-width: 900px) {
  .upload-demo{
    width: 100%;
    /deep/ .el-upload{
      width: 100% !important;
      .el-upload-dragger{
        width: 100% !important;
        height: auto !important;
      }
    }
    .el-upload__tip{
      height: auto !important;
      line-height: 20px;
    }
  }
}
</style>
