<template>
  <div class="form-title">
    <h1>{{ infoName }}</h1>
  </div>
</template>

<script>
export default {
  name: 'FormTitle',
  props: ['infoName'],
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="less">
.form-title {
  width: 100%;
  height: 76px;
  //box-shadow: 0 2px 5px rgba(0, 85, 255, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  h1 {
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  h1::before {
    content: '';
    z-index: -1;
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 104px;
    height: 12px;
    margin-left: -52px;
    background-color: rgba(42, 171, 255, 0.3);
  }
}
@media screen and (max-width: 900px){
  .form-title{
    height: 50px;
    h1{
      font-size: 18px;
    }
  }
}
</style>
