<template>
  <!-- 滚动公告 -->
  <div class="notice-wrapper" v-if="notices && notices.length > 0">
    <div class="n-icon">
      <img :src="require('@/assets/images/icon/notice.png')" />
    </div>
    <el-carousel class="notice"
                 height="40px"
                 direction="vertical"
                 :interval="5000"
                 indicator-position="none"
                 :autoplay="true">
      <el-carousel-item v-for="(notice, index) in notices" :key="index">
        <p class="notice-item">{{ notice }}</p>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      notices: []
    }
  },
  created() {
    this.getNotice()
  },
  methods: {
    // 获取公告
    getNotice() {
      // CommonApi.getNotices().then((res) => {
      //   this.notices = res.data
      // })
      this.notices = JSON.parse(localStorage.getItem("notices"))
    }
  }
}
</script>
<style scoped lang="less">
.notice-wrapper {
  width: 600px;
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, rgba(255,255,255, 0), rgba(255, 191, 0, 0.25), rgba(255,255,255, 0));
  margin: 20px auto 0;
  color: #787d87;
  display: flex;
  align-items: center;
  justify-content: center;
  .n-icon {
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .notice {
    width: 64%;
    .notice-item {
      text-align: center;
      white-space: nowrap;
      font-size: 12px;
      color: #787d87;
    }
  }
}
@media screen and (max-width: 900px){
  .notice-wrapper {
    display: none;
  }
}
</style>
