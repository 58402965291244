import CommonApi from "@/api/common"
export const orderMixin = {
  methods: {
    //生成订单
    createOrder(data,paramsData){
      let dataParams = paramsData
      let dataInfo = data
      let _this = this
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      if(localStorage.getItem('shareCode')){
        dataInfo.shareCode = localStorage.getItem('shareCode')
      }
      CommonApi.createOrder(dataInfo,key,time,infoMd5).then(res => {
        dataParams.info = res.data.id
        _this.$router.push({name: 'IntelligentPay', params: dataParams})
      }).catch(() => {
      })
    },
  }
}
